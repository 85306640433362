<template>
  <div
      class="flex flex-row w-full text-a-neutral-darkest shadow-sm"
      v-click-outside="() => {
          searchStore.hideSearchSuggestions();
        }"
  >
    <div class="flex flex-col w-10/12 relative">
      <!-- Search Query Input -->
      <input
          type="text"
          class="text-xs md:text-sm h-10 w-full rounded-l bg-white border border-a-neutral-dim border-r-0 p-2 focus:outline-none font-semibold"
          placeholder="Search Fundraising Categories Or Campaigns..."
          v-model="localSearchQuery"
          @input="getInstantSearchResults"
      />
      <!-- Search Query Input Ends -->
      <!-- Search Suggestions Div -->
      <div
          :class="[
            'flex flex-col w-full absolute top-10 bg-white border-b-2 border-l-2 border-r-2 rounded-b shadow-xl z-40',
          ]"
          v-if="viewSearchSuggestionsList"
      >
        <!-- Fundraising Campaign Categories Suggestions -->
        <BaseInstantSearchSuggestionsList
            v-if="fundraisingCampaignCategorySearchSuggestions.length > 0"
            :suggestions="fundraisingCampaignCategorySearchSuggestions"
            highlighting-field-name="title"
            list-title="Categories"
            :current-search-query="localSearchQuery"
            @suggestionSelected="
              (selectedCategorySuggestion) =>
                searchSuggestedFundraisingCampaignCategory(
                  selectedCategorySuggestion
                )
            "
        />
        <span class="w-full border-b border-a-neutral-dim"></span>
        <!-- Fundraising Campaigns Suggestions -->
        <BaseInstantSearchSuggestionsList
            v-if="fundraisingCampaignSearchSuggestions.length > 0"
            :suggestions="fundraisingCampaignSearchSuggestions"
            highlighting-field-name="title"
            list-title="Fundraisers"
            :current-search-query="localSearchQuery"
            @suggestionSelected="
              (selectedCampaignSuggestion) =>
                gotoSuggestedFundraisingCampaignPage(selectedCampaignSuggestion)
            "
        />
      </div>
      <!-- Search Suggestions Div Ends -->
    </div>
    <BaseButton
        primary-color-class="a-primary"
        text-color-class="text-a-neutral-darkest"
        width-class="h-10 w-2/12 rounded-r rounded-l-none"
        @click.native="gotoSearchPage()"
        :loading="isSearching"
        loadingColor="#000000"
        loadingSize="7px"
    >
      <Icon name="mdi:magnify"/>
    </BaseButton>
  </div>
</template>

<script
    setup
    lang="ts"
>

import {useMainFundraisingDataManager} from "#imports";

const searchStore = useSearch();

const localSearchQuery = ref("");
const fundraisingCampaignCategorySearchSuggestions = ref([]);
const fundraisingCampaignSearchSuggestions = ref([]);
const router = useRouter();
const route = useRoute();

const isSearching = computed(() => {
  return searchStore.is_searching;
});

const viewSearchSuggestionsList = computed(() => {
  return (
      searchStore.is_showing_search_suggestions
      &&
      (fundraisingCampaignCategorySearchSuggestions.value.length > 0 ||
          fundraisingCampaignSearchSuggestions.value.length > 0)
  );
});

watch(localSearchQuery, () => {
  if (localSearchQuery.value === "") {
    searchStore.hideSearchSuggestions();
  }
  searchStore.setSearchQuery(localSearchQuery.value)
});

watch(() => route.query, () => {
  localSearchQuery.value =
      searchStore.current_search_query;
})

watch(route, () => {
      searchStore.isNotSearching()
    },
    {
      deep: true,
      immediate: true,
    });

onMounted(() => {
  if (searchStore.current_search_query) {
    localSearchQuery.value =
        searchStore.current_search_query;
  } else {
    route.query.q !== null
        ? (localSearchQuery.value = route.query.q as string)
        : (localSearchQuery.value = route.query.c as string);
  }
});

async function getInstantSearchResults() {
  if (localSearchQuery.value !== "" && localSearchQuery.value.length > 1) {
    searchStore.showSearchSuggestions();
    const results = await useMainFundraisingDataManager().getFundraisingCampaignInstantSearchResults(localSearchQuery.value)
    if (results) {
      fundraisingCampaignCategorySearchSuggestions.value =
          results.fundraising_campaign_categories_results;
      fundraisingCampaignSearchSuggestions.value =
          results.fundraising_campaign_results;
    }
  }
}

async function gotoSearchPage() {
  initiateSearch();
  if (useRoute().name === 'fundraising-search') {
    await router.replace({
      query: {
        q: localSearchQuery.value == "" ? "" : localSearchQuery.value,
        ...searchStore.getFundraisingDefaultQueryParams(),
      },
    })
  } else {
    await navigateTo({
      name: "fundraising-search",
      query: {
        q: localSearchQuery.value == "" ? "" : localSearchQuery.value,
        ...searchStore.getFundraisingDefaultQueryParams(),
      },
    })
  }
}

function searchSuggestedFundraisingCampaignCategory(category) {
  localSearchQuery.value = category.title;
  initiateSearch();
  navigateTo({
    name: "fundraising-search",
    query: {
      c: category.title,
      ci: category.id,
      ...searchStore.getFundraisingDefaultQueryParams(),
    }
  });
}

function gotoSuggestedFundraisingCampaignPage(fundraisingCampaign) {
  localSearchQuery.value = "";
  initiateSearch();
  navigateTo({
    name: "fundraising-slug",
    params: {
      slug: fundraisingCampaign.slug,
    },
  });
}

function initiateSearch() {
  searchStore.hideSearchSuggestions();
  searchStore.isSearching();
}

</script>

<style></style>
