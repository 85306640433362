<template>
  <div
      class="flex flex-none w-full border-b-2 border-a-neutral-dim bg-white pb-1"
  >
    <div
        class="md:flex items-center justify-start w-full max-w-screen-2xl h-8 mx-auto px-4 hidden"
    >
      <!-- Categories Dropdown -->
      <BaseMultiLevelSmartDropdown
          :categories="fundraisingCampaignCategories"
          :current-selected-category-id="currentSelectedCategoryId"
          @selectedCategory="
          (category) => {
            searchSuggestedFundraisingCampaignCategory(category);
          }
        "
      />
      <!-- Categories Dropdown Ends -->
      <!-- Categories -->
      <p
          @click="searchSuggestedFundraisingCampaignCategory(category)"
          v-for="category in fundraisingCampaignCategories"
          :key="category.id"
          class="font-semibold text-sm hover:text-a-secondary mx-4 cursor-pointer focus:outline-none"
          :class="currentSelectedCategoryId===category.id.toString()?'text-a-secondary':'text-a-neutral-darkest'"

      >
        {{ category.title }}
      </p>
      <!-- Categories Ends -->
    </div>
    <div class="flex md:hidden w-full">
      <div
          class="swiper fundraisingNavCategoriesCarousel w-full h-16 bg-white categories"
      >
        <div class="swiper-wrapper">
          <div
              class="swiper-slide font-semibold text-2xl text-center max-w-36"
              v-for="category in fundraisingCampaignCategories"
              :key="category.id"
              @click="searchSuggestedFundraisingCampaignCategory(category);"
          >

            <div class="flex flex-row items-center justify-center h-full w-full">
              <p class="shadow-xl mx-1 text-xs rounded-full px-1 py-1 w-full border-2 "
                 :class="currentSelectedCategoryId===category.id.toString()?'bg-a-secondary-lightest border-a-secondary text-a-secondary':'bg-white border-a-neutral-light text-a-neutral-darkest '"
              >
                {{ category.title }}</p>
            </div>
          </div>
        </div>
        <Icon
            name="mdi:chevron-left"
            class="swiper-button-prev text-6xl text-a-neutral-darkest"
            :class="['categories-button-prev']"
        />
        <Icon
            name="mdi:chevron-right"
            class="swiper-button-next text-6xl text-a-neutral-darkest"
            :class="['categories-button-next']"
        />
      </div>
    </div>
  </div>
</template>

<script
    setup
    lang="ts"
>

import {Navigation} from "swiper/modules";

function searchSuggestedFundraisingCampaignCategory(category) {
  useSearch().setSearchQuery(category.title);
  navigateTo({
    name: "fundraising-search",
    query: {
      c: category.title,
      ci: category.id,
      sort: "latest",
    },
  });
}

//TODO: Must add goto Categories or when category slide is pressed
function gotoSubCategoriesPage() {
}

const fundraisingCampaignCategories = await useFundraisingDataManager().getFundraisingCampaignCategories();

const currentSelectedCategoryId = computed(()=>{
  const selectedCategoryId = useRoute().query.ci;
  if(selectedCategoryId){
    return selectedCategoryId.toString();
  }
  return '';
})

const swiper = ref(null);
onMounted(() => {
  swiper.value = new useNuxtApp().$swiper('.fundraisingNavCategoriesCarousel', {
    modules: [Navigation],
    direction: "horizontal",
    slidesPerView: "3",
    grabCursor: true,
    loop: false,
    navigation: {
      nextEl: `.categories-button-next`,
      prevEl: `.categories-button-prev`
    },
    effect: "slide",
    allowTouchMove: true,
    observer: true,
  });
})


</script>
<style>
.categories-button-prev {
  @apply text-a-neutral-darkest;
}

.categories-button-next {
  @apply text-a-neutral-darkest;
}
</style>

